import React from "react";
import Carousel from 'react-bootstrap/Carousel';

function CarouselSA() {
  return (
    <Carousel pause="hover" controls={false}>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="./images/carousel1.png"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="./images/carousel2.png"
          alt="First slide"
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="./images/carousel3.png"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="./images/carousel4.png"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="./images/carousel5.png"
          alt="First slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselSA;