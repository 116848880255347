const getPrecios = async () => {
  const filePath = "./precios.json";
  try {
    const response = await fetch(filePath);
    return response.json();
  } catch (error) {
    return error;
  }
};

export default getPrecios;
