import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import MaintenancePage from "./components/MaintenancePage";

import "./App.css";
import Template from "./components/Template";

function App() {
  return (
    <>
        <Router>
          <Routes>
            <Route path="/aa" element={<Template section="AA" />} />
            <Route path="/sa-main" element={<Template section="SA-MAIN" />} />
            <Route path="/sa-blog" element={<Template section="SA-BLOG" />} />
            <Route path="/ad" element={<Template section="AD" />} />
            <Route path="/nn" element={<Template section="NN" />} />
            <Route index element={<Navigate to="/aa" />} />
          </Routes>
        </Router>
    </>
  );
}

export default App;
