import React from "react";

const ContentAD = () => {
  return (
   <h1>
   aaa dddd
   </h1>
  );
};

export default ContentAD;
