import React from "react";
import Container from "react-bootstrap/Container";

const Footer = () => {
  const currentYear = new Date().getFullYear() + " - AA Lúdica";
  return (
    <Container className="mt-3">
      <div className="navFooter"></div>
    </Container>
  );
};

export default Footer;
