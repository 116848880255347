import React from "react";

const ContentNN = () => {
  return (
   <h1>
   nnn nnn
   </h1>
  );
};

export default ContentNN;
