import React, { useState } from "react";
import SectionNavSA from "../components/SectionNavSA";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import CarouselSA from "../components/CarouselSA";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import VolumenModal from "../components/VolumenModal";
import { BsFillPeopleFill, BsFillClockFill } from "react-icons/bs";

import background from "../assets/bkg1.jpg";

const ContentSA = () => {
  const [selectedVol, setSelectedVol] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (vol) => {
    setSelectedVol(vol);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
    <div
      className="contentContainer"
      style={{ backgroundImage: `url(${background}`, height: "100%" }}
    >
      <VolumenModal
        show={showModal}
        handleClose={handleCloseModal}
        vol={selectedVol}
      />
      <SectionNavSA />
      <section className="landingSA">
        <Row>
          <Col>
            {" "}
            <h1 className="fjalla pt-5" >
              Deslumbrá a tus amigos con tu memoria y aprendé historia argentina
              jugando.{" "}
            </h1>
            <br />{" "}
            <h5 className="copete">
              ¡Poné a prueba tu conocimiento! Competí con tus amigos por ser el
              primero en armar la mayor línea de tiempo de sucesos políticos,
              económicos y populares de nuestro país.{" "}
            </h5>
            <Container fluid className="sucesos-stats">
              <Row className="justify-content-center">
                <Col className="text-center">
                  <span className="stat-icon">
                    <BsFillPeopleFill />
                  </span>
                </Col>
                <Col className="text-center">
                  <span className="stat-icon">
                    {" "}
                    <BsFillClockFill />
                  </span>
                </Col>
                <Col className="text-center">
                  <span className="stat-icon-age">10</span>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="text-center py-2">
                  <h4 className="txt-beige fjalla copyico">
                    de 2 a 4 jugadores
                  </h4>{" "}
                  <h6 className="txt-beige-sub">
                    (hasta 6 jugadores si juntás
                    <br /> 2 o más volúmenes){" "}
                  </h6>
                </Col>
                <Col className="text-center py-2">
                  <h4 className="txt-beige fjalla copyico">
                    de 15 a 20 minutos{" "}
                  </h4>{" "}
                </Col>
                <Col className="text-center">
                  <h4 className="txt-beige fjalla stat-subtext">años o más </h4>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col style={{ display: "flex", justifyContent: "right" }} md="auto">
            <img src="/images/cartas1_sa.png" alt="cartas" />
          </Col>
        </Row>
      </section>
      <section>
        <div className="p5 text-center">
          <h1 className="fjalla">Las cartas </h1>
        </div>
        <Container fluid className="sucesos-volumenes">
          <Row className="justify-content-center">
            <Col className="text-center">
              <Card style={{ width: "18rem", border: "1px solid grey" }}>
                <Card.Img variant="top" src="/images/vol1_sa.jpg" />
                <Card.Body>
                  <Card.Text>Volumen 1</Card.Text>
                  <Card.Title>Gobiernos y Conflictos</Card.Title>
                  <Button
                    variant="secondary"
                    onClick={() => handleOpenModal(1)}
                  >
                    Contenido
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className="text-center">
              <Card style={{ width: "18rem", border: "1px solid grey" }}>
                <Card.Img variant="top" src="/images/vol2_sa.jpg" />
                <Card.Body>
                  <Card.Text>Volumen 2</Card.Text>
                  <Card.Title>Economía y Sociedad</Card.Title>
                  <Button
                    variant="secondary"
                    onClick={() => handleOpenModal(2)}
                  >
                    Contenido
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className="text-center">
              <Card style={{ width: "18rem", border: "1px solid grey" }}>
                <Card.Img variant="top" src="/images/vol3_sa.jpg" />
                <Card.Body>
                  <Card.Text>Volumen 3</Card.Text>
                  <Card.Title>Ciencia y Cultura</Card.Title>
                  <Button
                    variant="secondary"
                    onClick={() => handleOpenModal(3)}
                  >
                    Contenido
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className="text-center">
              <Card style={{ width: "18rem", border: "1px solid grey" }}>
                <Card.Img variant="top" src="/images/vol4_sa.jpg" />
                <Card.Body>
                  <Card.Text>Volumen 4</Card.Text>
                  <Card.Title>Cambalache</Card.Title>
                  <Button
                    variant="secondary"
                    onClick={() => handleOpenModal(4)}
                  >
                    Contenido
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="comoSeJuegaSA">
        <div className="p5 text-center">
          <h1 className="fjalla">¿Cómo se juega? </h1>
        </div>
        <Container className="">
          <CarouselSA />
        </Container>
      </section>
    </div>
    </>
  );
};

export default ContentSA;
