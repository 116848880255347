import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const VolumenModal = ({ show, handleClose, vol }) => {
    let volTitle = "";
    let volContent = "";
    switch (vol){
        case 1: 
        volTitle = "Volumen 1: Gobiernos y Conflictos";
        volContent = "El volumen preferido de los historiadores. Incluye todos los representantes del poder politico del país (Presidentes, Gobernadores, etc), así como las más representativas batallas y combates. Incluye cartas como “El Cruce de los Andes”, “Guerra de Malvinas” y “Declaración de la Independencia”";
        break;
        case 2: 
        volTitle = "Volumen 2: Economía y Sociedad";
        volContent = "Un recorrido por los hechos que marcaron la idiosincracia popular, como “El Cordobazo”, “El Pacto de Olivos” y “La Inauguración del Primer Ferrocarril”";
        break;
        case 3: 
        volTitle = "Volumen 3: Ciencia y Cultura";
        volContent = "¡Esto también es historia! Incluye eventos como “Fundación de la UBA”, “Invención del by-pass” y “Segunda Copa FIFA Argentina”.";
        break;
        case 4: 
        volTitle = "Volumen 4: Cambalache";
        volContent = "La cultura popular que nos define. Incluye “Inauguración de Pumper Nic”, “Primer vuelo del Pulqui” y “Primera ascención al Aconcagua”.";
        break;
        default: break;
    }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{volTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{volContent}</Modal.Body>
    </Modal>
  );
};

export default VolumenModal;
