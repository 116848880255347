import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import axios from "axios";
import getPrecios from "../../../utils/precios";

const ContentAA = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [precios, setPrecios] = useState();
  const [showRequiredFields, setShowRequiredFields] = useState(false);

  const handleClose = () => setShowContactModal(false);
  const handleShow = () => setShowContactModal(true);

  const handleSend = async () => {
    const data = {
      nombre: document.getElementById("nombre").value,
      email: document.getElementById("email").value,
      telefono: document.getElementById("telefono").value,
      mensaje: document.getElementById("mensaje").value,
    };
    if (data.nombre && data.email && data.mensaje) {
      const res = await axios
        .post("https://aaludica.com.ar/api/sendmail.php", data)
        .then(setShowContactModal(false));
      if (res.status === 200) {
        Swal.fire({
          title: "Mensaje enviado",
          text: "¡Muchas gracias!",
          icon: "info",
          confirmButtonText: "Aceptar",
        }).then(() => console.log("Mensaje enviado"));
      } else {
        Swal.fire({
          title: "Error",
          text: "Hubo un error en el envío:" + res.statusText,
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      setShowRequiredFields(true);
    }
  };

  useEffect(() => {
    getPrecios();
  }, [precios]);

  return (
    <>
      <Modal
        show={showContactModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Contacto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form.Group className="mb-3">
              <Form.Label>Nombre</Form.Label>
              {showRequiredFields && (
                <div className="requiredField">Este campo es requerido</div>
              )}
              <Form.Control type="text" id="nombre" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              {showRequiredFields && (
                <div className="requiredField">Este campo es requerido</div>
              )}
              <Form.Control type="text" id="email" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Teléfono / WhatsApp</Form.Label>
              <Form.Control type="text" id="telefono" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mensaje</Form.Label>
              {showRequiredFields && (
                <div className="requiredField">Este campo es requerido</div>
              )}
              <Form.Control as="textarea" rows={4} id="mensaje"></Form.Control>
            </Form.Group>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleSend}>
            Enviar
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="contentContainer">
        <Navbar
          bg="dark"
          data-bs-theme="dark"
          fixed="top"
          className="navbarHeader"
        >
          <Container>
            <Navbar.Brand href="/aa" className="m-auto">
              <img src="./images/logo_aa.png" alt="logo" className="responsiveImage"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto ">
                <Nav.Link
                  href="https://www.instagram.com/aa.ludica/?hl=es"
                  target="_blank"
                >
                  <img src="./images/icon_ig.png" alt="instagram" />
                </Nav.Link>
                <Nav.Link href="https://wa.me/541158418487/" target="_blank">
                  <img src="./images/icon_wa.png" alt="whatsapp" />
                </Nav.Link>
                <Nav.Link href="#" onClick={handleShow}>
                  <img src="./images/icon_email.png" alt="email" />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container className="containerWithNavbar">
          <Row lg={3}>
            <Col className="d-flex">
              <Card className="productCard">
                <Card.Img variant="top" src="images/thumb_sa.png" />
                <Card.Body className="text-center">
                  <img src="images/titulo_sa.png" alt="titulo"></img>
                  <Card.Text>
                    Un juego donde se pone a prueba tu conocimiento, compitiendo
                    con tus amigos por ser el primero en armar en una línea de
                    tiempo con cartas que describen varios de los hitos de la
                    historia de nuestro país.
                  </Card.Text>
                  <Row>
                    <Col>
                      <div className="divPrecio">$ 14.000</div>
                    </Col>
                    <Col>
                      {" "}
                      <Button variant="danger" onClick={handleShow}>Comprar</Button>
                      {/* <Button variant="warning" className="m-2" href="/sa-main">
                        Ver más
                      </Button> */}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="productCard">
                <Card.Img variant="top" src="images/thumb_ad.png" />
                <Card.Body className="text-center">
                  <img src="images/titulo_ad.png" alt="titulo"></img>
                  <Card.Text>
                    Ars Domino te permite conocer algunas de las obras más
                    importantes del arte universal de forma amena. Utiliza las
                    reglas del dominó tradicional, pero para poder jugar tus
                    fichas tendrás que saber a qué período pertenecen.
                  </Card.Text>{" "}
                  <Row>
                    <Col></Col>
                    <Col>
                      {" "}
                      <span className="divAgotado">
                        <strong>SIN STOCK</strong>
                      </span>
                      {/* <Button variant="warning" className="m-2" href="/ad-main">
                        Ver más
                      </Button> */}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="productCard">
                <Card.Img variant="top" src="images/thumb_nn.png" />
                <Card.Body className="text-center">
                  <img src="images/titulo_nn.png" alt="titulo"></img>
                  <Card.Text>
                    Un juego de riesgos para toda la familia en el que tratás de
                    convertirte en el roedor más gordito de todos. Cada comida
                    que te lleves te da puntos pero cuidado, comer mucho de la
                    misma comida te pueda caer mal.
                  </Card.Text>{" "}
                  <Row>
                    <Col>
                      <div className="divPrecio">$ 9.000</div>
                    </Col>
                    <Col>
                      {" "}
                      <Button variant="danger" onClick={handleShow}>Comprar</Button>
                      {/* <Button variant="warning" className="m-2" href="/nm-main">
                        Ver más
                      </Button> */}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContentAA;
