import React from "react";
import Footer from "./Footer";
import ContentSA from "./SA/pages/ContentSA";
import BlogSA from "./SA/pages/BlogSA";
import ContentAA from "./AA/pages/ContentAA";
import ContentAD from "./AD/pages/ContentAD";
import ContentNN from "./NN/pages/ContentNN";

const Template = ({ section }) => {
  return (
    <>
      {section === "AA" && <ContentAA />}
      {section === "SA-MAIN" && <ContentSA />}
      {section === "SA-BLOG" && <BlogSA />}
      {section === "AD" && <ContentAD />}
      {section === "NN" && <ContentNN />}
      <Footer></Footer>
    </>
  );
};

export default Template;
