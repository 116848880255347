import React from "react";
import { Container } from "react-bootstrap";
import SectionNavSA from "../components/SectionNavSA";
import background from "../assets/bkg1.jpg";

const BlogSA = () => {
  return (
    <div
      className="contentContainer"
      style={{ backgroundImage: `url(${background}` , height: "100vh"}}
    >
      <SectionNavSA />
      <Container className="blog-container-sa">
        <section className="blog-section-sa">
            <h3>Presentación del Volumen 4</h3>
            <h6>Noviembre 2021</h6>
            <div>Participamos del Encuentro Nacional de Juegos de Mes 2021 en Adrogué, Buenos Aires. Presentamos el flamante Volumen 4, "Cambalache".</div>
        </section>
        <section className="blog-section-sa">aaaa2</section>
      </Container>
    </div>
  );
};

export default BlogSA;
